<template>
    <div>
        <kendo-grid ref="grid"
                    :data-source="dataSource"
                    :pageable="true"
                    :filterable="true"
                    :sortable="true"
                    :columns="columns"
                    :resizable="true">
        </kendo-grid>
    </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import userService from '../Script/UserServices.js';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'UserGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['editClick', 'deleteClick'],
    data(){
        return{
            dataSource: new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: false,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function() {
                            return { query: userService.readQuery() };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    }
                },
                schema: {
                    data: function(response) {
                        if(response.data.GetUser == null){
                            return [];
                        }else{
                            return response.data.GetUser;
                        }
                    },
                    total: function(response) {
                        if(response.data.GetUser == null){
                            return 0;
                        }else{
                            return response.data.GetUser.length;
                        }
                    }
                },
            }),
            columns:  [
                { title: "Action", width: 150, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "active_flag", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    template: this.statusView
                },
                { field: "username", title: "Username", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "myname", title: "Name", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "rname", title: "Role", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "contact_name", title: "Kontak", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }
            ],
        }
    },
    mounted: function(){
        var deleteClick = this.$props.deleteClick;
        var editClick = this.$props.editClick;
        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;
        
        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem, true);
        })

        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
           
            deleteClick(dataItem.userid);
        })
    },
    methods: {
        columnButton(){
            return this.$globalfunc.gridActionButton("User")
        },
        statusView(e){
            if(e.active_flag == 'N'){
                return 'New'
            }else if(e.active_flag == 'A'){
                return 'Active'
            }else if(e.active_flag == 'I'){
                return 'Inactive'
            }
            else{
                return 'Error'
            }
        }
    }
}
</script>

<style scoped>
</style>