<template>
    <div>
        <div class="modal fade" id="userModal" tabindex="-1" aria-labelledby="userModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="userModalLabel" class="font-weight-bold">{{ModalTitle}}{{Username}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CCardBody>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Username</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="Username" v-model="Username" class="font-weight-bold" />
                                    <label id="errorUsername" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Name</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="Name" v-model="Name" class="font-weight-bold" />
                                    <label id="errorName" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>
                            <CRow id="passwordRow">
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Password</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="Password" v-model="Password" class="font-weight-bold" />
                                    <label id="errorPassword" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Role</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <v-select id="Role" class="pb-3" :options="RoleData" v-model="Role" />
                                    <label id="errorRole" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Active Flag</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CSelect id="ActiveFlag" class="font-weight-bold" :options="ActiveFlagData" :value.sync="ActiveFlag"/>
                                    <label id="errorActiveFlag" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Kontak</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <v-select id="Contact" class="pb-3" :options="ContactData" v-model="Contact" />
                                    <label id="errorContact" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>
                            <br>
                            <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;">Save</CButton>
                            <label style="float: right;">&nbsp;&nbsp;</label>
                            <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close">Close</CButton>
                        </CCardBody> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import userServices from '../Script/UserServices.js';
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import { response } from '../../../../infrastructure/constant/response';

export default {
    name: 'UserForm',
    props: ['reload'],
    components: {
    },
    data: function () {
        return {        
            ModalTitle : '',
            SaveType : '',
            Error: 0,

            Id: '',
            Username: '',
            Name: '',
            Password: '',
            Role: '',
            RoleData: [],
            ActiveFlagData: [],
            ActiveFlag: '',
            ContactData: [],
            Contact: null,
        }
    },
    mounted(){

    },
    methods: {
        async addClick(){
            $(".form-error").css("display", "none");
            this.ModalTitle = 'Add User: ';

            this.Username = '';
            this.Name = '';
            this.Password = '';
            this.RoleData = await userServices.getRoleQuery();
            this.Role = '';

            this.ActiveFlagData = [{value: 'N', label: 'New'}];
            this.ActiveFlag = 'N';

            this.ContactData = await userServices.GetContactList();
            this.Contact = null;

            this.SaveType = 'Add';
            
            document.getElementById("Username").readOnly = false;
            document.getElementById("Password").readOnly = false;
            window.$('#userModal').modal('show');
        },
        async editClick(data, view){
            $(".form-error").css("display", "none");
            this.ModalTitle = 'Edit User: ';

            this.Id = data.userid;
            this.Username = data.username;
            this.Name = data.myname;
            this.Password = data.mypwd;
            this.RoleData = await userServices.getRoleQuery();
            this.Role = this.RoleData.find(c => c.value == data.roleid);

            this.ActiveFlagData = [   
                {value: 'A', label: 'Active'},
                {value: 'I', label: 'Inactive'}
            ];
            this.ActiveFlag = data.active_flag == "N" ? "A" : data.active_flag;

            this.ContactData = await userServices.GetContactList();
            this.Contact = this.ContactData.find(c => c.value == data.contact_id);

            this.SaveType = 'Edit';

            document.getElementById("Username").readOnly = true;
            document.getElementById("Password").readOnly = true;

            if(view){
                $(".save-button").hide();
                this.ModalTitle = 'View User: ';
            }

            window.$('#userModal').modal('show');
        },
        async inputValidation() {            
            this.Error = 0;
            $(".form-error").css("display", "none");

            if(this.Username == '' || this.Username == null){
                this.errorShow('errorUsername');
            }
            if(this.Name == '' || this.Name == null){
                this.errorShow('errorName');
            }
            if(this.Role == '' || this.Role == null){
                this.errorShow('errorRole');
            }
            if(this.ActiveFlag == '' || this.ActiveFlag == null){
                this.errorShow('errorActiveFlag');
            }     
            if(this.SaveType == 'Add'){
                if(this.Password == '' || this.Password == null){
                    this.errorShow('errorPassword');
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
        saveClick() {
            this.inputValidation();
            
            if(this.Error == 0){
                var contact = this.Contact == null ? null : this.Contact.value;
                const data = {
                    username : this.Username,
                    mypwd : this.Password,
                    myname : this.Name,
                    contact_id : contact,
                    roleid : this.Role.value,
                    active_flag: this.ActiveFlag,
                };

                //Add
                if(this.SaveType == 'Add'){
                    this.$loading(true);

                    const variables = {
                        data : data
                    };

                    userServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success");
                        window.$('#userModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error);
                        this.$swal("Error", errorMessage, "error");
                    })
                }
                //Edit
                else if(this.SaveType == 'Edit'){
                    this.$loading(true);
                    
                    const variables = {
                        id : this.Id.toString(),
                        data : data
                    }

                    userServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success");
                        window.$('#userModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error);
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },  
    },
    
}
</script>
<style scoped>
.col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 12%;
    }
    .col-sm-content {
        width: 21%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}
>>> {
    --vs-controls-size: 0.7;
    --vs-actions-padding: 4px 2px 0 3px;
    --vs-border-color: #d8dbe0;
    --vs-selected-color: #768192;
    --vs-dropdown-option-color: #768192;
    --vs-selected-max-height: 10px;
}
</style>